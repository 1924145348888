import * as React from "react"
import { LayoutFlat } from "../../components/flat/layout"
import MastheadFlat from "../../components/flat/masthead"
import BlogListingFlat from "../../components/flat/blog-listing"

const InsightsFlat = () => (
    <LayoutFlat>
        <MastheadFlat />
        <BlogListingFlat />
    </LayoutFlat>
)

export default InsightsFlat
